import React, { useState } from "react"
import { connect } from "react-redux"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableBody from "@material-ui/core/TableBody"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"

const usd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const AnnuityCalculator = () => {
  const [amount, setAmount] = useState(1000000)
  const [increment, setIncrement] = useState(5)
  const [years, setYears] = useState(30)

  const rate = () => 1 + increment / 100.0
  const initialPayout = () => amount * (1 - rate()) / (1 - rate() ** years)

  const nextPayoutValue = allPayouts => allPayouts[0].payout * rate()

  const nextPayout = allPayouts => [{
    "payout": nextPayoutValue(allPayouts),
    "sum": allPayouts[0].sum + nextPayoutValue(allPayouts),
  }].concat(allPayouts)

  const payouts = () => [...Array(years - 1)].reduce(nextPayout, [{
    "payout": initialPayout(),
    "sum": initialPayout(),
  }]).reverse()

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h4" component="h2">Annuity Calculator</Typography>
        <br/>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField label="Jackpot value" onChange={e => setAmount(parseInt(e.target.value))} defaultValue={amount}
                       InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                       type="number" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Yearly increment" onChange={e => setIncrement(parseFloat(e.target.value))} defaultValue={increment} type="number"
                       InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Number of payouts (years)" onChange={e => setYears(parseInt(e.target.value))} defaultValue={years} type="number" min={1} />
          </Grid>
        </Grid>
        <br/>
        <Typography variant="subtitle2">
          Annuity Payouts Breakdown:
        </Typography>
        <br/>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell>Payout</TableCell>
              <TableCell>Sum of payouts so far</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {years > 0 && increment > 0 && payouts().map((payout, i) => <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{usd.format(payout.payout)}</TableCell>
              <TableCell>{usd.format(payout.sum)}</TableCell>
            </TableRow>)}
          </TableBody>
        </Table>
      </Box>
    </Paper>
  )
}
const mapStateToProps = ({ gameOptions }) => ({ gameOptions })

export default connect(mapStateToProps)(AnnuityCalculator)
