import React from "react"
import { graphql, Link } from "gatsby"
import AnnuityCalculator from "../components/tools/AnnuityCalculator"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FAQItem from "../components/FAQItem"

const LotteryAnnuityCalculator = ({ data, location }) =>
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title="Lottery Annuity Payout Calculator"
         description="When you hit the lottery jackpot you have the option to choose the cash value (also known as lump sum) - grabbing a single big prize, or you can go with the annuity option - receiving smaller, continuously incrementing payments throughout the next couple of years. But how do you know how much money you'll get each year?" />
    <h1>Lottery Annuity Payout Calculator</h1>
    <p>
      When you hit the lottery jackpot you have the option to choose the cash value (also known as lump sum) - grabbing
      a single big prize, or you can go with the annuity option - receiving smaller, continuously incrementing payments
      throughout the next couple of years. But how do you know how much money you'll get each year?
    </p>
    <strong>
      Using the lottery annuity payout calculator you can see the estimated value of the different payout instalments
      for each year. The exact amount depends on the rules of the actual game - but most lotteries use a 5% increment
      and a 30 year period. The sum of the individual payments should equal to the advertised jackpot value.
    </strong>
    <br/>
    <br/>
    <AnnuityCalculator />
    <h2>FAQ</h2>
    <FAQItem question="Do The Numbers Displayed By The Annuity Calculator Include Taxes?">
      <strong>All figures are before taxes.</strong> Federal and state taxes along with possible additional deductions
      may apply.
    </FAQItem>
    <FAQItem question="How Are Annuity Payout Values Calculated?">
      <strong>The sum of the individual payouts should equal to the total jackpot value. The payouts are
        incrementing by constant ratio year-by-year.
      </strong> In mathematics this is called a <a href="https://en.wikipedia.org/wiki/Geometric_progression"
                                                   target="_blank" rel="noreferrer">geometric progression</a>. To
      calculate the first payout you can solve the formula used to calculate the sum of the numbers in a geometric
      progression. If you have the first element you can easily calculate the rest iteratively.
    </FAQItem>
    <FAQItem question="How Are Powerball Annuity Payouts Calculated?">
      <strong>Powerball jackpot winners will receive 30 payments over 29 years, each payment increasing by 5% - if they
        choose the annuity options.</strong>
    </FAQItem>
    <FAQItem question="How Are Mega Millions Annuity Payouts Calculated?">
      <strong>If you win Mega Millions and opt for the annuity payout option, you'll be guaranteed to receive 30 payments for the next 29 years. Each payment will be 5% larger than the previous one.</strong>
    </FAQItem>
    <FAQItem question="How Much Does It Cost to Use the Lottery Annuity Payout Calculator? Is It a Free Tool?">
      <strong>Our lottery annuity payout calculator tool is free to
        use.</strong> There are no hidden fees or charges.<br />
      <i>Please note: We might show you 3<sup>rd</sup> party ads or affiliate links. You can read more about this in
        our <Link to="/terms-and-conditions/">T&amp;C document</Link>.</i>
    </FAQItem>
    <h2>Disclaimer</h2>
    <section>
      This tool is intended for informational, educational, and entertainment purposes only.
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <Link to="/lottery-number-generator/">Lottery Number Generator</Link>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/lottery-odds-calculator/">Lottery Odds Calculator</Link>
        </li>
      </ul>
      <ul>
        <li>
          <Link to="/lottery-tax-calculator/">Lottery Tax Calculator</Link>
        </li>
      </ul>
    </section>
    <h2>Similar Tools</h2>
    <section>
      <ul>
        <li>
          <a
            href="https://en.wikipedia.org/wiki/Geometric_progression" target="_blank" rel="noreferrer">geometric
            progression</a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            href="https://powerball.com" target="_blank" rel="noreferrer">Powerball</a>
        </li>
      </ul>
      <ul>
        <li>
          <a
            href="https://www.megamillions.com/FAQs.aspx" target="_blank" rel="noreferrer">Mega Millions FAQ</a>
        </li>
      </ul>
    </section>
  </Layout>

export default LotteryAnnuityCalculator

export const pageQuery = graphql`
query {
  site {
  siteMetadata {
  title
}
}
}
`
